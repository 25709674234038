/* eslint-disable */
/**
 * @fileoverview gRPC-Web generated client stub for nodebuzz
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from 'grpc-web';

import {
  BuzzEvent,
  BuzzFeedRequest,
  BuzzRandomTeamRequest,
  BuzzRequest,
  ChangeExclusionRequest,
  ClientState,
  CreateTeamRequest,
  DeleteInstanceRequest,
  DeleteInstanceResponse,
  DeleteTeamRequest,
  GetClientStateRequest,
  GetServerStateRequest,
  GetSettingsRequest,
  JoinInstanceRequest,
  JoinInstanceResponse,
  JoinTeamRequest,
  KeepAliveRequest,
  ResetPointsRequest,
  Response,
  ServerState,
  SetBuzzersEnabledRequest,
  SetDeviceNameRequest,
  SetPointsRequest,
  SetTeamNameRequest,
  Settings,
  SpawnInstanceRequest,
  SpawnInstanceResponse,
  UpdateSettingsRequest} from './nodebuzz_pb';

export class NodeBuzzClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetServerState = new grpcWeb.AbstractClientBase.MethodInfo(
    ServerState,
    (request: GetServerStateRequest) => {
      return request.serializeBinary();
    },
    ServerState.deserializeBinary
  );

  getServerState(
    request: GetServerStateRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/GetServerState',
      request,
      metadata || {},
      this.methodInfoGetServerState);
  }

  methodInfoGetClientState = new grpcWeb.AbstractClientBase.MethodInfo(
    ClientState,
    (request: GetClientStateRequest) => {
      return request.serializeBinary();
    },
    ClientState.deserializeBinary
  );

  getClientState(
    request: GetClientStateRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/GetClientState',
      request,
      metadata || {},
      this.methodInfoGetClientState);
  }

  methodInfoGetSettings = new grpcWeb.AbstractClientBase.MethodInfo(
    Settings,
    (request: GetSettingsRequest) => {
      return request.serializeBinary();
    },
    Settings.deserializeBinary
  );

  getSettings(
    request: GetSettingsRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/GetSettings',
      request,
      metadata || {},
      this.methodInfoGetSettings);
  }

  methodInfoBuzzFeed = new grpcWeb.AbstractClientBase.MethodInfo(
    BuzzEvent,
    (request: BuzzFeedRequest) => {
      return request.serializeBinary();
    },
    BuzzEvent.deserializeBinary
  );

  buzzFeed(
    request: BuzzFeedRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/BuzzFeed',
      request,
      metadata || {},
      this.methodInfoBuzzFeed);
  }

  methodInfoBuzz = new grpcWeb.AbstractClientBase.MethodInfo(
    Response,
    (request: BuzzRequest) => {
      return request.serializeBinary();
    },
    Response.deserializeBinary
  );

  buzz(
    request: BuzzRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: Response) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/Buzz',
      request,
      metadata || {},
      this.methodInfoBuzz,
      callback);
  }

  methodInfoSetDeviceName = new grpcWeb.AbstractClientBase.MethodInfo(
    Response,
    (request: SetDeviceNameRequest) => {
      return request.serializeBinary();
    },
    Response.deserializeBinary
  );

  setDeviceName(
    request: SetDeviceNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: Response) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/SetDeviceName',
      request,
      metadata || {},
      this.methodInfoSetDeviceName,
      callback);
  }

  methodInfoSetTeamName = new grpcWeb.AbstractClientBase.MethodInfo(
    Response,
    (request: SetTeamNameRequest) => {
      return request.serializeBinary();
    },
    Response.deserializeBinary
  );

  setTeamName(
    request: SetTeamNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: Response) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/SetTeamName',
      request,
      metadata || {},
      this.methodInfoSetTeamName,
      callback);
  }

  methodInfoCreateTeam = new grpcWeb.AbstractClientBase.MethodInfo(
    Response,
    (request: CreateTeamRequest) => {
      return request.serializeBinary();
    },
    Response.deserializeBinary
  );

  createTeam(
    request: CreateTeamRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: Response) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/CreateTeam',
      request,
      metadata || {},
      this.methodInfoCreateTeam,
      callback);
  }

  methodInfoJoinTeam = new grpcWeb.AbstractClientBase.MethodInfo(
    Response,
    (request: JoinTeamRequest) => {
      return request.serializeBinary();
    },
    Response.deserializeBinary
  );

  joinTeam(
    request: JoinTeamRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: Response) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/JoinTeam',
      request,
      metadata || {},
      this.methodInfoJoinTeam,
      callback);
  }

  methodInfoChangeExclusion = new grpcWeb.AbstractClientBase.MethodInfo(
    Response,
    (request: ChangeExclusionRequest) => {
      return request.serializeBinary();
    },
    Response.deserializeBinary
  );

  changeExclusion(
    request: ChangeExclusionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: Response) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/ChangeExclusion',
      request,
      metadata || {},
      this.methodInfoChangeExclusion,
      callback);
  }

  methodInfoBuzzRandomTeam = new grpcWeb.AbstractClientBase.MethodInfo(
    Response,
    (request: BuzzRandomTeamRequest) => {
      return request.serializeBinary();
    },
    Response.deserializeBinary
  );

  buzzRandomTeam(
    request: BuzzRandomTeamRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: Response) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/BuzzRandomTeam',
      request,
      metadata || {},
      this.methodInfoBuzzRandomTeam,
      callback);
  }

  methodInfoResetPoints = new grpcWeb.AbstractClientBase.MethodInfo(
    Response,
    (request: ResetPointsRequest) => {
      return request.serializeBinary();
    },
    Response.deserializeBinary
  );

  resetPoints(
    request: ResetPointsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: Response) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/ResetPoints',
      request,
      metadata || {},
      this.methodInfoResetPoints,
      callback);
  }

  methodInfoUpdateSettings = new grpcWeb.AbstractClientBase.MethodInfo(
    Response,
    (request: UpdateSettingsRequest) => {
      return request.serializeBinary();
    },
    Response.deserializeBinary
  );

  updateSettings(
    request: UpdateSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: Response) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/UpdateSettings',
      request,
      metadata || {},
      this.methodInfoUpdateSettings,
      callback);
  }

  methodInfoSetPoints = new grpcWeb.AbstractClientBase.MethodInfo(
    Response,
    (request: SetPointsRequest) => {
      return request.serializeBinary();
    },
    Response.deserializeBinary
  );

  setPoints(
    request: SetPointsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: Response) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/SetPoints',
      request,
      metadata || {},
      this.methodInfoSetPoints,
      callback);
  }

  methodInfoDeleteTeam = new grpcWeb.AbstractClientBase.MethodInfo(
    Response,
    (request: DeleteTeamRequest) => {
      return request.serializeBinary();
    },
    Response.deserializeBinary
  );

  deleteTeam(
    request: DeleteTeamRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: Response) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/DeleteTeam',
      request,
      metadata || {},
      this.methodInfoDeleteTeam,
      callback);
  }

  methodInfoSpawnInstance = new grpcWeb.AbstractClientBase.MethodInfo(
    SpawnInstanceResponse,
    (request: SpawnInstanceRequest) => {
      return request.serializeBinary();
    },
    SpawnInstanceResponse.deserializeBinary
  );

  spawnInstance(
    request: SpawnInstanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: SpawnInstanceResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/SpawnInstance',
      request,
      metadata || {},
      this.methodInfoSpawnInstance,
      callback);
  }

  methodInfoDeleteInstance = new grpcWeb.AbstractClientBase.MethodInfo(
    DeleteInstanceResponse,
    (request: DeleteInstanceRequest) => {
      return request.serializeBinary();
    },
    DeleteInstanceResponse.deserializeBinary
  );

  deleteInstance(
    request: DeleteInstanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: DeleteInstanceResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/DeleteInstance',
      request,
      metadata || {},
      this.methodInfoDeleteInstance,
      callback);
  }

  methodInfoJoinInstance = new grpcWeb.AbstractClientBase.MethodInfo(
    JoinInstanceResponse,
    (request: JoinInstanceRequest) => {
      return request.serializeBinary();
    },
    JoinInstanceResponse.deserializeBinary
  );

  joinInstance(
    request: JoinInstanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: JoinInstanceResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/JoinInstance',
      request,
      metadata || {},
      this.methodInfoJoinInstance,
      callback);
  }

  methodInfoSetBuzzersEnabled = new grpcWeb.AbstractClientBase.MethodInfo(
    Response,
    (request: SetBuzzersEnabledRequest) => {
      return request.serializeBinary();
    },
    Response.deserializeBinary
  );

  setBuzzersEnabled(
    request: SetBuzzersEnabledRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: Response) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/SetBuzzersEnabled',
      request,
      metadata || {},
      this.methodInfoSetBuzzersEnabled,
      callback);
  }

  methodInfoKeepAlive = new grpcWeb.AbstractClientBase.MethodInfo(
    Response,
    (request: KeepAliveRequest) => {
      return request.serializeBinary();
    },
    Response.deserializeBinary
  );

  keepAlive(
    request: KeepAliveRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: Response) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/nodebuzz.NodeBuzz/KeepAlive',
      request,
      metadata || {},
      this.methodInfoKeepAlive,
      callback);
  }

}

