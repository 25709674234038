import React, { useContext } from 'react';
import { HashRouter as Router, Route, Redirect } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';

import './App.css';
import { APIClient } from './State/APIClient';
import { TeamSelection } from './TeamSelection/TeamSelection';
import { Session } from "./Prerequisites/Session";
import { Configure } from "./Configure/Configure"
import { Settings as SettingsStateProvider, SettingsState } from './State/Settings';
import { BuzzerView } from './Buzzer/Buzzer';
import { Monitor } from './Monitor/Monitor';
import { Admin } from './Admin/Admin';

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8888"


const Index: React.FC = () => {
  let settings = useContext(SettingsState)
  if (!settings.session)
    return <Redirect to="/session" />

  return <Redirect to="/teams" />
}

const Logout: React.FC = () => {
  let settings = useContext(SettingsState)
  settings.setSession(null)
  settings.setAdminSession(null)
  return <Redirect to="/session" />
}

const WithAPI: React.FC<{ admin?: boolean, user?: boolean, instance?: string, children: React.ReactNode }> = ({ admin, user, instance, children }) => {
  let settings = useContext(SettingsState)
  let session = undefined
  if (admin)
    session = settings.adminSession
  if (user)
    session = settings.session
  if (session === null) {
    if (user)
      return <Redirect to="/session" />
    session = undefined
  }
  return (
    <APIClient apiURL={apiUrl} session={session} instance={instance} isDevice={user}>
      {children}
    </APIClient>
  )
}

const App: React.FC = () => {
  return (
    <div className="App" style={{ padding: '10px' }}>
      <SettingsStateProvider>
        <Router>
          <Route path="/" exact component={Index} />
          <Route path="/logout" exact component={Logout} />

          <Route path="/session" exact component={() => <WithAPI><Session /></WithAPI>} />
          <Route path="/session/:instance" exact component={({ match }: any) => <WithAPI><Session instanceStatic={match.params.instance}/></WithAPI>} />
          <Route path="/join" exact component={() => <WithAPI><Session /></WithAPI>} />
          <Route path="/join/:instance" exact component={({ match }: any) => <WithAPI><Session instanceStatic={match.params.instance}/></WithAPI>} />

          <Route path="/buzzer" exact component={() => <WithAPI user><BuzzerView /></WithAPI>} />
          <Route path="/teams" exact component={() => <WithAPI user><TeamSelection /></WithAPI>} />
          <Route path="/configure" exact component={() => <WithAPI user><Configure /></WithAPI>} />

          <Route path="/monitor/:instance" exact component={({ match } : any) => <WithAPI instance={match.params.instance}><Monitor /></WithAPI>} />
          <Route path="/admin" exact component={() => <WithAPI admin><Admin /></WithAPI>} />
        </Router>
      </SettingsStateProvider>
    </div>
  );
}

export default App;
