import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


import * as Sentry from '@sentry/browser';
if (process.env.NODE_ENV !== "development") {
    Sentry.init({ dsn: "https://65d9826cfac341ceb506702a3df453be@sentry.ente.ninja/3" });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
