import React, { useContext, useEffect, useState } from 'react'
import { Container, Row, Col, Jumbotron, Input, Button, InputGroup, InputGroupAddon, InputGroupText, Progress } from 'reactstrap'
import { APIState, request, AdminConfig, IAPIState, APIStream } from '../State/APIClient'
import { SettingsState, ISettingsState } from '../State/Settings'
import { Login as AdminLogin } from './Login'
import { Teams as AdminTeams } from './Teams'
import { BuzzRandomTeamRequest, Settings, UpdateSettingsRequest, SetBuzzersEnabledRequest, ResetPointsRequest, SetPointsRequest, BuzzFeedRequest, BuzzEvent } from '../API/nodebuzz_pb'
import Octicon, { X, Check } from '@primer/octicons-react'
import { Link } from 'react-router-dom'
import { GlobalHotKeys } from "react-hotkeys";
import { NeedData } from '../Prerequisites/NeedData'


const keyMap = {
    INC_POINTS: "plus",
    DEC_POINTS: "-",
    TOGGLE_REENABLE: 'o',
    TOGGLE_BUZZER: 'space',
    TOGGLE_TEAM_CHANGE: 't'
};

function updateSettings(api: IAPIState, settings: ISettingsState, update: Partial<AdminConfig>) {
    if (api.config === null)
        throw new Error("updateSettings with api.config === null")
    let { timeout, allowTeamChanging, autoReenable, soundEnabled } = { ...api.config, ...update }
    let s = new Settings()
    s.setTimeout(timeout)
    s.setAllowteamchanging(allowTeamChanging)
    s.setAutoreenable(autoReenable)
    s.setSoundenabled(soundEnabled)
    let req = new UpdateSettingsRequest()
    req.setSettings(s)
    req.setSession(settings.adminSession!)
    request(api, req, p => p.updateSettings)
}


export const Config: React.FC = () => {
    let api = useContext(APIState)
    let settings = useContext(SettingsState)
    if (!api.config)
        return <div>missing admin config</div>
    let config = api.config

    let update = (update: Partial<AdminConfig>) => updateSettings(api, settings, update)

    let Checkbox: React.FC<{
        children: React.ReactNode,
        checked: boolean,
        onChange: (checked: boolean) => void
    }> = ({ children, checked, onChange }) =>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <Button style={{
                        backgroundColor: checked ? '#b15315' : '#df691a',
                        color: 'white'
                    }} onClick={_ => onChange(!checked)}>
                        <Octicon icon={checked ? Check : X}></Octicon>
                    </Button>
                </InputGroupAddon>
                <InputGroupText style={{
                    color: '#ebebeb',
                    backgroundColor: '#4e5d6c',
                }}>{children}</InputGroupText>
            </InputGroup>

    return (
        <>
            <Row>
                <Col>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">Timeout</InputGroupAddon>
                        <Input type="number" value={config.timeout} onChange={e => update({ timeout: parseInt(e.target.value) })} />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Checkbox checked={config.autoReenable} onChange={autoReenable => update({ autoReenable })}>
                        Auto re-enable the buzzers!
                    </Checkbox>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Checkbox checked={config.soundEnabled} onChange={soundEnabled => update({ soundEnabled })}>
                        Sound enabled
                    </Checkbox>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Checkbox checked={config.allowTeamChanging} onChange={allowTeamChanging => update({ allowTeamChanging })}>
                        Allow Team Changing
                    </Checkbox>
                </Col>
            </Row>
        </>
    )
}

export const Admin: React.FC = () => {
    let api = useContext(APIState)
    let settings = useContext(SettingsState)

    let [buzzer] = useState(() => {
        let fx = new Audio("audio/buzzer.wav")
        fx.preload = "auto";
        fx.volume = 0.5
        return fx
    })

    let soundEnabled = api.config ? api.config.soundEnabled : false

    useEffect(() => {
        if (!api.platform || !soundEnabled) return
        let req = new BuzzFeedRequest()
        req.setSession(settings.adminSession!)

        return new APIStream({
            stream: api.platform.buzzFeed,
            req,
            name: 'buzzFeed',
            onData: (event: BuzzEvent) => buzzer.play(),
            platform: api.platform,
            setStreamError: () => {/*TODO*/ }
        }).cleanup
    }, [settings.adminSession, api.platform, soundEnabled, buzzer])

    if (settings.adminSession === null)
        return <AdminLogin />
    let adminSession = settings.adminSession

    if (api.config === null || api.serverState === null)
        return <NeedData />

    let buzzersEnabled = api.serverState.getBuzzersenabled()

    let lastWinner = api.serverState.getTeamsList().find(team => team.getUuid() === api.serverState!.getLastwinner())

    const Cooldown: React.FC<{ cooldown: number, timeout: number }> = ({ cooldown, timeout }) => {
        if (cooldown === -1)
            return null
        return <span>
            <div style={{ textAlign: 'center' }}>Cooldown: {cooldown}s</div>
            <Progress animated value={Math.round((1 - (cooldown / timeout)) * 100)} />
        </span>
    }

    const handlers = {
        INC_POINTS: () => {
            let team = api.serverState!.getTeamsList().find(team => team.getUuid() === api.serverState!.getLastwinner());
            if (!team)
                throw new Error("INC_POINTS without lastWinner team")
            let req = new SetPointsRequest()
            req.setTeamuuid(team.getUuid())
            req.setSession(settings.adminSession!)
            req.setPoints(team.getScore() + 1)
            request(api, req, p => p.setPoints)
        },
        DEC_POINTS: () => {
            let team = api.serverState!.getTeamsList().find(team => team.getUuid() === api.serverState!.getLastwinner());
            if (!team)
                throw new Error("INC_POINTS without lastWinner team")
            let req = new SetPointsRequest()
            req.setTeamuuid(team.getUuid())
            req.setSession(settings.adminSession!)
            req.setPoints(team.getScore() - 1)
            request(api, req, p => p.setPoints)
        },
        TOGGLE_BUZZER: (event?: KeyboardEvent) => {
            if (event)
                event.preventDefault()
            let req = new SetBuzzersEnabledRequest()
            req.setEnabled(!api.serverState!.getBuzzersenabled())
            req.setSession(settings.adminSession!)
            request(api, req, p => p.setBuzzersEnabled)
        },
        TOGGLE_REENABLE: () => {
            updateSettings(api, settings, { autoReenable: !api.config!.autoReenable })
        },
        TOGGLE_TEAM_CHANGE: () => {
            updateSettings(api, settings, { allowTeamChanging: !api.config!.allowTeamChanging })
        }
    };

    return <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges><Container>
        <Jumbotron style={{ paddingTop: '1em' }}>
            <h1>ReactBuzz 3.141 <span role="img" aria-label="b">🅱️</span>remium Admin Interface</h1>
            <h2>{api.serverState.getInstanceid()}- <Link to={"/monitor/" + api.serverState.getInstanceid()}>Monitor</Link> - <Link to="/logout">Logout</Link></h2>
            <Row><h2>Buzzer Control</h2></Row>
            <Row>
                <Col md={{ size: 4 }}>
                    <Button block
                        style={{ backgroundColor: buzzersEnabled ? '#df691a' : undefined }}
                        onClick={() => {
                            let req = new SetBuzzersEnabledRequest();
                            req.setSession(adminSession)
                            req.setEnabled(!buzzersEnabled)
                            request(api, req, p => p.setBuzzersEnabled)
                        }}>{buzzersEnabled ? 'Disable ' : 'Enable '} Buzzers!</Button>
                </Col>
                <Col md={{ size: 4 }}>
                    <Button block onClick={() => {
                        let req = new BuzzRandomTeamRequest()
                        req.setSession(adminSession)
                        request(api, req, p => p.buzzRandomTeam)
                    }}>Buzz a random Team</Button>
                </Col>
                <Col md={{ size: 4 }}>
                    <Button block onClick={() => {
                        if (window.prompt("Confirm Reset? (type 'y')") !== 'y')
                            return
                        let req = new ResetPointsRequest()
                        req.setSession(adminSession)
                        request(api, req, p => p.resetPoints)
                    }}>Reset Points</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Cooldown cooldown={api.serverState.getCooldown()} timeout={api.config.timeout} />
                </Col>
            </Row>
            {lastWinner ? (
                <>
                    <Row><h2>Last Buzz Winner Team</h2></Row>
                    <Row style={{ textAlign: 'center' }}>
                        <span style={{ width: '100%' }}>
                            <h5>{lastWinner.getName()}</h5>
                            <h6>
                                (
                                {lastWinner.getScore()}
                                {lastWinner.getScore() === 1 ? ' point' : ' points'}
                                )
                            </h6>
                        </span>
                    </Row>
                </>
            ) : null}
            <Row><h2>Configuration</h2></Row>
            <Config />
            <Row><h2>List of Teams</h2></Row>
            <AdminTeams />
        </Jumbotron>
    </Container>
    </GlobalHotKeys>
}