import React, { useContext, useState } from 'react'
import { APIState, request } from '../State/APIClient'
import { ListGroup, ListGroupItem, Input, InputGroup, InputGroupAddon, InputGroupText, Badge, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import Octicon, { Settings } from '@primer/octicons-react'
import { DndProvider, useDrag, useDrop, DragSourceMonitor } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { SetPointsRequest, Team, BuzzRequest, SetTeamNameRequest, ChangeExclusionRequest, DeleteTeamRequest, Device, JoinTeamRequest } from '../API/nodebuzz_pb'
import { SettingsState } from '../State/Settings'

const TeamMember: React.FC<{ member: Device }> = ({ member }) => {
    let settings = useContext(SettingsState)
    let api = useContext(APIState)
    const [{ isDragging }, drag] = useDrag({
        item: { name: member.getUuid(), type: 'DEVICE' },
        end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                let req = new JoinTeamRequest()
                req.setDeviceuuid(item.name)
                req.setTeamuuid(dropResult.name)
                req.setSession(settings.adminSession!)
                request(api, req, p => p.joinTeam)
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    if (isDragging)
        console.log('testdrag')

    let online = member.getLastseentimestamp() > new Date().getTime() - 13000
    return <span ref={drag} key={member.getUuid()} style={{ cursor: 'move' }}>
        <ListGroupItem style={{
            textAlign: "right",
            backgroundColor: 'rgba(0,0,0,0.2)',
            color: isDragging ? 'gray' : 'white'
        }} key={member.getUuid()}>
            {member.getName()} {' '}
            <Badge color={online ? 'success' : 'danger'}>{online ? 'Online' : 'Offline'}</Badge>
        </ListGroupItem>
    </span>
}

const TeamEntry: React.FC<{ team: Team }> = ({ team }) => {
    let settings = useContext(SettingsState)
    let api = useContext(APIState)
    let [settingsDropdown, setSettingsDropdown] = useState(false);

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'DEVICE',
        drop: () => ({ name: team.getUuid() }),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    if (!api.serverState)
        return null
    let serverState = api.serverState
    let adminSession = settings.adminSession!


    let keys = team.getMembersMap().toArray().map(([k, _]) => k).sort()
    let members = keys.map(k => team.getMembersMap().get(k)!)
    return <ListGroup key={team.getUuid()}>
        <ListGroupItem style={{
            textAlign: 'left',
            backgroundColor: serverState.getLastwinner() === team.getUuid() ? '#df691a' : 'grey',
            color: 'white',
            userSelect: 'none',
        }} key={team.getUuid()} >
            {team.getName()}
            {' '}
            {serverState.getLastwinner() === team.getUuid() ? <Badge color="primary">BUZZED</Badge> : null}
            {team.getExcluded() ? <Badge color="danger">EXCLUDED</Badge> : null}
            {!team.getIslobby() ? (
                <Dropdown style={{ float: 'right' }} toggle={() => setSettingsDropdown(!settingsDropdown)} isOpen={settingsDropdown}>
                    <DropdownToggle>
                        <Octicon icon={Settings} size="medium" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => {
                            let req = new BuzzRequest()
                            req.setTeamuuid(team.getUuid())
                            req.setSession(adminSession)
                            request(api, req, p => p.buzz)
                        }}>Buzz</DropdownItem>
                        <DropdownItem onClick={() => {
                            let name = window.prompt("Team Name")
                            if (!name) return
                            let req = new SetTeamNameRequest()
                            req.setTeamuuid(team.getUuid())
                            req.setSession(adminSession)
                            req.setNewname(name)
                            request(api, req, p => p.setTeamName)
                        }}>Rename</DropdownItem>
                        <DropdownItem onClick={() => {
                            let req = new ChangeExclusionRequest()
                            req.setTeamuuid(team.getUuid())
                            req.setSession(adminSession)
                            req.setExcluded(!team.getExcluded())
                            request(api, req, p => p.changeExclusion)
                        }}>{team.getExcluded() ? 'Un-Exclude' : 'Exclude'}</DropdownItem>
                        <DropdownItem onClick={() => {
                            if (window.prompt("Delete Team? (type 'y')") !== 'y')
                                return
                            let req = new DeleteTeamRequest()
                            req.setTeamuuid(team.getUuid())
                            req.setSession(adminSession)
                            request(api, req, p => p.deleteTeam)
                        }}>Delete</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            ) : null}
            {!team.getIslobby() ? (
                <span style={{ textAlign: 'right', float: 'right', paddingRight: '1em' }}>
                    <InputGroup>
                        <Input style={{ width: '4.5em' }} type="number" value={team.getScore()} onChange={e => {
                            let req = new SetPointsRequest()
                            req.setTeamuuid(team.getUuid())
                            req.setPoints(parseInt(e.target.value))
                            req.setSession(settings.adminSession!)
                            request(api, req, p => p.setPoints)
                        }} />
                        <InputGroupAddon addonType="append">
                            <InputGroupText>
                                {team.getScore() === 1 ? ' point' : ' points'}
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </span>
            ) : null}
        </ListGroupItem>
        {members.map(member => <TeamMember key={member.getUuid()} member={member} />)}
        <span style={{ display: canDrop ? 'block' : 'none' }} ref={drop}>
            <ListGroupItem style={{
                backgroundColor: isOver ? '#df691a' : undefined
            }}>Move Device to Team</ListGroupItem>
        </span>
    </ListGroup>
}

export const Teams: React.FC = () => {
    let api = useContext(APIState)
    if (!api.serverState)
        return null

    let serverState = api.serverState

    return <DndProvider backend={HTML5Backend}>
        <Row>
            {serverState.getTeamsList().map(team => {
                return <Col key={team.getUuid()} md={6} style={{ paddingTop: '1em', paddingBottom: '1em' }}>
                    <TeamEntry team={team} />
                </Col>
            })}
        </Row>
    </DndProvider>
}