import React, { useState, useContext, useEffect } from 'react';
import { Container, Col, Row, Button, Form, FormGroup, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Octicon, { Settings } from '@primer/octicons-react'

import { BuzzRequest, BuzzEvent, BuzzFeedRequest } from '../API/nodebuzz_pb';
import { DeviceMotionProvider } from './DeviceMotion';
import { APIState, APIStream } from '../State/APIClient';
import { SettingsState } from '../State/Settings';
import { Link } from 'react-router-dom';
import { NeedData } from '../Prerequisites/NeedData';

export const SmolCol: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return <Col xs="12" sm={{ offset: 3, size: 6 }}>
        {children}
    </Col>
}

export const Buzzer: React.FC<{ onBuzz: () => void }> = ({ onBuzz }) => {
    let api = useContext(APIState)
    if (!api.clientState || !api.serverState)
        return <></>
    let team = api.clientState.getOwnteam()!
    let buzzerState = api.serverState.getBuzzersenabled() ? 'ENABLED' : 'DISABLED'
    if (api.serverState.getCooldown() !== -1)
        buzzerState = team.getBuzzedduringcooldown() ? 'TOO_LATE' : 'COOLDOWN'
    if (team.getExcluded() || team.getIslobby())
        buzzerState = 'EXCLUDED'

    return <Button
        block
        size="lg"
        style={{
            height: '3.5em',
            fontSize: '2.2em',
            backgroundColor: buzzerState === 'ENABLED' ? '#985f0d' : undefined
        }}
        disabled={buzzerState !== 'ENABLED'}
        onClick={onBuzz}>
        {buzzerState === 'DISABLED' ? 'DISABLED' : null}
        {buzzerState === 'EXCLUDED' ? 'EXCLUDED' : null}
        {buzzerState === 'TOO_LATE' ? 'TOO LATE' : null}
        {buzzerState === 'ENABLED' ? 'BUZZ!!!!!' : null}
        {buzzerState === 'COOLDOWN' ? (team ? api.serverState.getCooldown() + 's' : '?') : null}
    </Button>
}

export const BuzzerView: React.FC = () => {
    let settings = useContext(SettingsState)
    let api = useContext(APIState)
    let [settingsDropdown, setSettingsDropdown] = useState(false);

    let [buzzer] = useState(() => {
        let fx = new Audio("audio/buzzer.wav")
        fx.preload = "auto";
        fx.volume = 0.5
        return fx
    })

    let ownTeam = api.clientState && api.clientState.getOwnteam()
    const ownUuid = ownTeam && ownTeam.getUuid()
    useEffect(() => {
        if (api.platform === null || settings.session === null)
            return
        let req = new BuzzFeedRequest()
        req.setSession(settings.session!)

        return new APIStream({
            stream: api.platform.buzzFeed,
            req,
            name: 'buzzFeed',
            onData: (event: BuzzEvent) => {
                if (event && event.getTeamuuid() === ownUuid)
                    buzzer.play()
            },
            platform: api.platform,
            setStreamError: () => {/*TODO*/}
        }).cleanup
    }, [ownUuid, api.platform, settings.session, buzzer])


    if (api.clientState === null)
        return <NeedData />

    let team = api.clientState.getOwnteam()!

    let onBuzz = () => {
        if (api.platform) {
            console.log("Sending BuzzRequest!")
            let req = new BuzzRequest()
            req.setSession(settings.session!)
            api.platform.buzz(req, null, async (err, resp) => {
                if (err)
                    console.error(err)
            })
        }
    }

    return (
        <Container>
            <Row style={{ paddingBottom: '1em' }}>
                <SmolCol>
                    <Dropdown style={{ float: 'right' }} toggle={() => setSettingsDropdown(!settingsDropdown)} isOpen={settingsDropdown}>
                        <DropdownToggle>
                            <Octicon icon={Settings} size="medium" />
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem><Link to="/teams">Select Team</Link></DropdownItem>
                            {/* TODO: <DropdownItem>View Score</DropdownItem> */}
                            <DropdownItem><Link to="/configure">Configure Buzzer</Link></DropdownItem>
                            <DropdownItem><Link to="/logout">Logout</Link></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </SmolCol>
            </Row>
            <Row style={{ paddingBottom: '1em' }}>
                <SmolCol>
                    <Form style={{ textAlign: 'left', color: "white" }}>
                        <FormGroup>
                            <Label>Name:</Label>
                            <div style={{ backgroundColor: '#4e5d6c', padding: '9px' }}>
                                {api.clientState.getDevicename()}
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>Team:</Label>
                            <div style={{ backgroundColor: '#4e5d6c', padding: '9px' }}>
                                {team.getName()}
                                <span style={{ textAlign: 'right', float: 'right' }}>
                                    {team.getScore()}
                                    {team.getScore() === 1 ? ' point' : ' points'}
                                </span>
                            </div>
                        </FormGroup>
                    </Form>
                </SmolCol>
            </Row>
            <Row >
                <SmolCol>
                    <DeviceMotionProvider sensitivity={settings.sensitivity} onBuzz={onBuzz}>
                        <Buzzer onBuzz={onBuzz} />
                    </DeviceMotionProvider>
                </SmolCol>
            </Row>
        </Container >
    )
}