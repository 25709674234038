
import React, { useContext, useState } from 'react';
import { Button, Container, Col, Row, Input, Form, FormGroup, Label } from 'reactstrap';
import { APIState, request } from '../State/APIClient';
import { JoinInstanceRequest } from '../API/nodebuzz_pb';
import { SettingsState } from '../State/Settings';
import { Redirect } from 'react-router';

export const Session: React.FC<{instanceStatic?: string}> = ({ instanceStatic }) => {
    let api = useContext(APIState)
    let settings = useContext(SettingsState)
    let [instance, setInstance] = useState('quiznight-xx')
    let [name, setName] = useState(() => 'Device ' + Math.random().toString(16).slice(2))
    if (instanceStatic)
        instance = instanceStatic


    if (settings.session !== null)
        return <Redirect to="/" />

    return (
        <Container>
            <Row style={{ paddingBottom: '1em' }}>
                <Col xs="12" sm={{ offset: 4, size: 4 }}>
                    <Form style={{ textAlign: 'left', color: 'white' }}>
                        <FormGroup>
                            <Label>Game Instance:</Label>
                            <Input onChange={e => {
                                setInstance(e.target.value)
                            }} value={instance} disabled={instanceStatic !== undefined}></Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Device Name:</Label>
                            <Input onChange={e => {
                                setName(e.target.value)
                            }} value={name}></Input>
                        </FormGroup>
                        <Button block onClick={() => {
                            let req = new JoinInstanceRequest()
                            req.setInstance(instance)
                            req.setName(name)
                            request(
                                api,
                                req,
                                platform => platform.joinInstance,
                                resp => settings.setSession(resp.getSession())
                            )
                        }}>Join</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}