import { NodeBuzzClient } from "../API/NodebuzzServiceClientPb";

import { GetClientStateRequest, ClientState, GetSettingsRequest, GetServerStateRequest, ServerState, KeepAliveRequest, Settings } from "../API/nodebuzz_pb";
import React from "react";
import { Alert, Spinner } from "reactstrap";
import { Metadata, Error, ClientReadableStream } from "grpc-web";

interface IAPIClient {
    children: React.ReactNode;
    apiURL: string;
    session?: string;
    instance?: string;
    isDevice?: boolean;
}


export interface AdminConfig {
    allowTeamChanging: boolean,
    autoReenable: boolean,
    soundEnabled: boolean,
    timeout: number,
}

export interface IAPIState {
    clientState: ClientState | null,
    serverState: ServerState | null,
    config: AdminConfig | null,
    platform: NodeBuzzClient | null,
    setError: (error: string | null) => void
}

const APIState = React.createContext<IAPIState>({
    clientState: null,
    serverState: null,
    config: null,
    platform: null,
    setError: _ => { }
});

export function request<Req, Resp>(
    api: IAPIState,
    req: Req,
    reqfunc: (platform: NodeBuzzClient) => ((req: Req, metadata: Metadata | null, f: (err: Error, resp: Resp) => void) => void),
    respfunc?: (resp: Resp) => void) {
    if (!api.platform)
        return
    if ((req as any).toObject)
        console.log("request", (req as any).toObject())

    let handleError = (msg: string) => {
        if (msg === 'Request was aborted') return
        api.setError(msg)
        console.error(msg)
    }

    let f = reqfunc(api.platform)
    f.bind(api.platform)(req, null, (err, resp) => {
        if (err)
            handleError(err.message)
        if (!resp)
            return
        let respError = (resp as any).getError ? (resp as any).getError() : null
        if (respError)
            handleError(respError)
        else if (respfunc !== undefined)
            respfunc(resp)
    })
}

export interface IAPIStream<Req, Resp> {
    name: string,
    req: Req,
    stream: (request: Req, metadata?: Metadata) => ClientReadableStream<Resp>,
    platform: NodeBuzzClient,
    onData: (response: Resp) => void,
    setStreamError: (error: string | null) => void,
}

const RETRY_TIMEOUT = 5000;
export class APIStream<Req, Resp> {
    private name: string
    private req: Req
    private onData?: (response: Resp) => void
    private setStreamError?: (error: string | null) => void
    private stream: (request: Req, metadata?: Metadata) => ClientReadableStream<Resp>
    private retry?: NodeJS.Timer
    private cancel?: () => void
    constructor(params: IAPIStream<Req, Resp>) {
        this.cleanup = this.cleanup.bind(this)
        this.start = this.start.bind(this)
        this.name = params.name
        this.req = params.req
        this.onData = params.onData
        this.setStreamError = params.setStreamError
        this.stream = params.stream.bind(params.platform)
        if (params.setStreamError)
            params.setStreamError('Fetching ' + this.name)
        this.retry = setTimeout(this.start, 0)
    }

    start() {
        this.retry = undefined
        if (this.onData === undefined) {
            console.log("Stream::start", this.name, "after Stream::cleanup")
            return
        }
        console.log("Stream::start", this.name)
        const stream = this.stream(this.req).on('data', (response: Resp) => {
            console.log(this.name, response)
            if (this.onData)
                this.onData(response);
            if (this.setStreamError)
                this.setStreamError(null)
        }).on('error', (err: Error) => {
            console.log(this.name + " failed:", err);
            if (this.setStreamError)
                this.setStreamError(err.message)
            if (this.retry === undefined)
                this.retry = setTimeout(this.start, RETRY_TIMEOUT);
        }).on('status', (status: any) => {
            console.log(this.name, "status:", status);
        }).on('end', () => {
            console.log(this.name, "ended");
            if (this.retry === undefined)
                this.retry = setTimeout(this.start, RETRY_TIMEOUT);
        });

        this.cancel = stream.cancel ? stream.cancel.bind(stream) : undefined
    }

    cleanup() {
        console.log("Stream::cleanup", this.name)
        if (this.retry)
            clearTimeout(this.retry)
        if (this.cancel)
            this.cancel()
        this.onData = undefined
        this.setStreamError = undefined
    }
}

let globalPlatform: NodeBuzzClient | undefined = undefined

const APIClient: React.FC<IAPIClient> = (props) => {
    const [clientState, setClientState] = React.useState<ClientState | null>(null);
    const [serverState, setServerState] = React.useState<ServerState | null>(null);
    const [config, setConfig] = React.useState<AdminConfig | null>(null);
    const [platform, setPlatform] = React.useState<NodeBuzzClient | null>(null);

    const [error, setError] = React.useState<string | null>(null);
    const [streamError, setStreamError] = React.useState<string | null>(null)

    React.useEffect(() => {
        let _platform: NodeBuzzClient | null = null
        if (globalPlatform)
            _platform = globalPlatform
        else {
            _platform = new NodeBuzzClient(props.apiURL);
            const enableDevTools = window['__GRPCWEB_DEVTOOLS__' as any] as any || ((_: any) => { });
            enableDevTools([_platform]);
        }
        let platform = _platform!
        setPlatform(platform);

        let defer: Array<Function> = [];

        if (props.isDevice && props.session) {
            let req = new GetClientStateRequest()
            req.setSession(props.session)
            defer.push(new APIStream({
                name: 'getClientState',
                stream: platform.getClientState,
                req, platform,
                onData: setClientState,
                setStreamError,
            }).cleanup)
        }

        if (props.session || props.instance) {
            let req = new GetServerStateRequest()
            if (props.session) req.setSession(props.session)
            if (props.instance) req.setInstance(props.instance)
            defer.push(new APIStream({
                name: 'getServerState',
                stream: platform.getServerState,
                req, platform,
                onData: setServerState,
                setStreamError,
            }).cleanup)
        }

        if (props.session || props.instance) {
            let req = new GetSettingsRequest()
            if (props.session) req.setSession(props.session)
            if (props.instance) req.setInstance(props.instance)
            defer.push(new APIStream({
                stream: platform.getSettings,
                name: 'getSettings',
                req, platform,
                onData: (response: Settings) => {
                    setConfig({
                        allowTeamChanging: response.getAllowteamchanging(),
                        autoReenable: response.getAutoreenable(),
                        soundEnabled: response.getSoundenabled(),
                        timeout: response.getTimeout(),
                    })
                },
                setStreamError,
            }).cleanup)
        }

        const keepalive = () => {
            if (!props.isDevice) return
            let interval = setInterval(() => {
                if (props.session) {
                    let req = new KeepAliveRequest()
                    req.setSession(props.session)
                    platform.keepAlive(req, null, (err, resp) => {
                        if (err)
                            setStreamError(err.message)
                        else if (resp && resp.getError())
                            setStreamError(resp.getError())
                        else
                            setStreamError(null)
                    })
                }
            }, 7000)
            defer.push(() => clearInterval(interval))
        }

        keepalive();

        return () => {
            defer.forEach(func => func())
        };
    }, [props.apiURL, props.session, props.instance, props.isDevice]);

    return (
        <React.Fragment>
            <Alert color="danger" isOpen={error !== null} toggle={() => setError(null)}>
                {error}
            </Alert>
            {streamError ? <Spinner style={{ position: "absolute", left: '1em', top: '1em' }} color="light" /> : null}
            <APIState.Provider value={{
                platform,
                clientState,
                serverState,
                config,
                setError
            }}>
                {props.children}
            </APIState.Provider>
        </React.Fragment>
    )
};

export { APIClient, APIState };
