
import React, { useContext, useState } from 'react';
import { Button, Container, Col, Row, Alert } from 'reactstrap';
import { SettingsState } from '../State/Settings';

// TODO(someday): remove this once the HTML5 wake-lock API is ready
const NoSleep = require('nosleep.js') as any
let nosleep = new NoSleep()

export const SoundCheck: React.FC = () => {
    let settings = useContext(SettingsState)
    let [bleep] = useState(() => {
        let fx = new Audio("audio/bleep.wav")
        fx.preload = "auto";
        fx.volume = 0.5
        return fx
    })

    return (
        <Container>
            <Row style={{ paddingBottom: '1em' }}>
                <Col xs="12" sm={{ offset: 4, size: 4 }}>
                    <Alert>
                        You'll need to allow the browser to play sounds. <br />
                        {(window.DeviceMotionEvent && typeof (window.DeviceMotionEvent as any).requestPermission === 'function') ? <span>
                            You'll also be asked about device motion permissions. Those will be used to trigger the buzzer. <br />
                        </span> : null}
                        Click the button to allow it!
                    </Alert>
                    <Button block onClick={async () => {
                        if (window.DeviceMotionEvent && typeof (window.DeviceMotionEvent as any).requestPermission === 'function') {
                            console.log("iOS: requesting DeviceMotion permissions");
                            (window.DeviceMotionEvent as any).requestPermission()
                        }
                        nosleep.enable()

                        await bleep.play()
                        settings.completeSoundCheck()
                        console.log("passed soundcheck")
                    }}>Ok</Button>
                </Col>
            </Row>
        </Container>
    )
}