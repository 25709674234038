import React, { useContext } from 'react'
import { APIState } from '../State/APIClient'

import { ListGroup, ListGroupItem, Badge, Col, Row } from 'reactstrap'
import { NeedData } from '../Prerequisites/NeedData'

export const Monitor: React.FC = () => {
    let api = useContext(APIState)

    if (!api.serverState)
        return <NeedData />

    let serverState = api.serverState

    let teams = serverState.getTeamsList().filter(team => !team.getIslobby())
    teams.sort((a, b) => b.getScore() - a.getScore())

    let maxScore = teams.map(team => team.getScore()).find(_ => true)

    let titleText = serverState.getBuzzersenabled() ? 'Please Buzz!!' : 'Scoreboard'
    if (serverState.getCooldown() !== -1)
        titleText += ' (' + serverState.getCooldown() + ')'

    return <>
        <Row>
            <Col md={{size:8, offset: 2}}>
                <h1 style={{ color: 'white', fontSize: '4.5em' }}>
                    {titleText}
                </h1>
            </Col>
        </Row>

        <Row>
            <Col md={{size:8, offset: 2}} style={{fontSize: '2.5em'}}>
                {teams.map(team => {
                    return <ListGroup key={team.getUuid()}>
                        <ListGroupItem style={{
                            textAlign: 'left',
                            backgroundColor: team.getScore() === maxScore ? '#df691a' : 'grey',
                            color: 'white',
                            userSelect: 'none',
                        }} key={team.getUuid()} >
                            {team.getName()} {' '}
                            {serverState.getLastwinner() === team.getUuid() ? <Badge color="primary">BUZZED</Badge> : null}

                            <span style={{ textAlign: 'right', float: 'right', paddingRight: '1em' }}>
                                {team.getScore()}
                                {team.getScore() === 1 ? ' point' : ' points'}
                            </span>
                        </ListGroupItem>
                    </ListGroup>
                })}
            </Col>
        </Row>
    </>
}