import React, { useContext, useRef, useEffect, useState } from 'react';
import { Container, Row, Alert, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { DeviceMotionProvider, DeviceMotionState, handlerInstance } from '../Buzzer/DeviceMotion';
import { SettingsState } from '../State/Settings';
import { Link } from 'react-router-dom';
import Octicon, { Check } from '@primer/octicons-react';
import { SmolCol } from '../Buzzer/Buzzer';

const MotionCanvas: React.FC = () => {
    let motion = useContext(DeviceMotionState)
    let { sensitivity } = useContext(SettingsState)
    let canvasRef = useRef<HTMLCanvasElement>(null)
    let [animFrame, setAnimFrame] = useState(0)
    useEffect(() => {
        let rAF = window.requestAnimationFrame(() => setAnimFrame(animFrame + 1))
        const canvas = canvasRef.current
        if (!canvas) return
        const ctx = canvas.getContext('2d')
        if (!ctx) return

        let value = motion.getMaxMotion()

        let { width, height } = canvas

        let y = animFrame % height
        ctx.fillStyle = "white";
        ctx.fillRect(0, y, width, 1)
        ctx.fillStyle = "black";
        ctx.fillRect(0, y + 1, width, 1)

        ctx.fillStyle = 'blue';
        ctx.fillRect(0, y, width * value, 1)
        if (value > sensitivity) {
            ctx.fillStyle = 'green'
            if (sensitivity === 0) ctx.fillStyle = 'grey';
            ctx.fillRect(sensitivity * width, y, (value - sensitivity) * width, 1)
        }
        ctx.fillStyle = "black";
        ctx.fillRect(sensitivity * width, y, 1, 1)
        return () => window.cancelAnimationFrame(rAF)
    }, [motion, sensitivity, animFrame])
    return <canvas ref={canvasRef} style={{width: '100%'}} />
}

const MotionStatus: React.FC = () => {
    let settings = useContext(SettingsState)
    let motion = useContext(DeviceMotionState)
    return <>
        <Alert color={settings.sensitivity !== 0 ? "primary" : 'secondary'}>
            You cannot buzz in this screen! <br />
            Activations: {motion.buzzCount}
        </Alert>
        <Form style={{ color: "white" }}>
            <FormGroup>
                <Label>Triggering amplitude:</Label>
                <Input type="range" value={settings.sensitivity * 100} onChange={e => settings.setSensitivity(parseFloat(e.target.value) / 100)}></Input>
            </FormGroup>
            <FormGroup>
                <Label>Current amplitude:</Label>
                <MotionCanvas />
        <Button block style={{
            backgroundColor: 'orange',
            color: 'black'
        }} onClick={() => {
            settings.setSensitivity(settings.sensitivity === 0 ? 0.5 : 0)
        }}>{settings.sensitivity === 0 ? 'Enable ' : 'Disable '} Motion Events</Button>
        <Button block onClick={() => {
            let fx = new Audio("audio/buzzer.wav")
            fx.volume = 0.5
            fx.play()
        }}>Test Buzzer Sound</Button>
            </FormGroup>
        </Form>
        {motion.apiStatus.deviceMotionEvents === 'PERMISSION_DENIED' ? <Button block style={{backgroundColor: '#df691a'}} onClick={() => {
            if (handlerInstance)
                handlerInstance.requestPermission()
        }}>
            Request Permission
        </Button> : null}
        {motion.apiStatus.deviceMotionEvents !== 'WORKING' && motion.apiStatus.sensors !== 'WORKING' ? (
            <Alert color="danger">
                Motion is not supported!
                <pre style={{ color: 'black', textAlign: 'left' }}>{JSON.stringify(motion.apiStatus, null, 2)}</pre>
            </Alert>
        ) : null}
    </>
}

export const Configure: React.FC = () => {
    let settings = useContext(SettingsState)
    return (
        <Container>
            <Row style={{ paddingBottom: '1em' }}>
                <SmolCol>
                    <Link to="/buzzer">
                        <Button style={{ float: 'right' }}>
                            <Octicon icon={Check} size="medium" />
                        </Button>
                    </Link>
                </SmolCol>
            </Row>
            <Row>
                <SmolCol>
                    <DeviceMotionProvider sensitivity={settings.sensitivity} >
                        <MotionStatus />
                    </DeviceMotionProvider>
                </SmolCol>
            </Row>
        </Container>
    )
}
