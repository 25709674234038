
import React, { useContext, useState } from 'react';
import { ListGroup, ListGroupItem, Button, Container, Row, Alert } from 'reactstrap';
import Octicon, { Check, ListUnordered, Plus, Pencil } from '@primer/octicons-react'
import { APIState, request } from '../State/APIClient';
import { CreateTeamRequest, SetTeamNameRequest, JoinTeamRequest } from '../API/nodebuzz_pb';
import { Link, Redirect } from 'react-router-dom';
import { SettingsState } from '../State/Settings';
import { SmolCol } from '../Buzzer/Buzzer';
import { NeedData } from '../Prerequisites/NeedData';

export const TeamSelection: React.FC = () => {
    let api = useContext(APIState)
    let { platform, clientState, serverState, config } = api
    let settings = useContext(SettingsState)
    let [teamsOpen, setTeamsOpen] = useState<boolean>(false);

    let allowTeamChanging = config ? config.allowTeamChanging : true
    teamsOpen = teamsOpen && allowTeamChanging

    if (settings.session === null)
        return <Redirect to="/" />

    if (!platform || !clientState || !serverState)
        return <NeedData />

    let team = clientState.getOwnteam()!
    let teams = serverState.getTeamsList()

    return (
        <Container>
            <Row style={{ paddingBottom: '1em' }}>
                <SmolCol>
                    <Link to="/buzzer">
                        <Button style={{ float: 'right' }}>
                            <Octicon icon={Check} size="medium" />
                        </Button>
                    </Link>
                </SmolCol>
            </Row>
            <Row>
                <SmolCol>
                    <Alert color="primary" style={{ fontSize: '1.4em' }}>Your are in:<br />{team.getName()}</Alert>
                </SmolCol>
            </Row>
            <Row style={{ paddingBottom: '1em' }}>
                <SmolCol>
                    <Button block size="lg" onClick={() => {
                        let name = window.prompt("New Team Name?")
                        if (!name)
                            return
                        let req = new CreateTeamRequest()
                        req.setSession(settings.session!)
                        req.setName(name)
                        request(api, req, platform => platform.createTeam)
                    }} disabled={settings.session === null || !allowTeamChanging}>
                        Open a new team
                        <span style={{ float: 'right' }}>
                            <Octicon icon={Plus} size="medium" />
                        </span>
                    </Button>
                </SmolCol>
            </Row>
            <Row>
                <SmolCol>
                    <Button block size="lg" color={teamsOpen ? "dark" : undefined} onClick={() => setTeamsOpen(!teamsOpen)} disabled={!allowTeamChanging}>
                        Join a team <span style={{ float: 'right' }}><Octicon icon={ListUnordered} size="medium" /></span>
                    </Button>
                    {teamsOpen ? (
                        <ListGroup>
                            {teams.map(listTeam => {
                                const isOwnTeam = team && listTeam.getUuid() === team.getUuid()
                                let keys = listTeam.getMembersMap().toArray().map(([k, _]) => k).sort()
                                let members = keys.map(k => listTeam.getMembersMap().get(k)!)
                                return <React.Fragment key={listTeam.getUuid()}>
                                    <ListGroupItem style={{
                                        textAlign: 'left',
                                        backgroundColor: isOwnTeam ? '#df691a' : 'grey',
                                        color: 'white',
                                        userSelect: 'none',
                                    }} key={listTeam.getUuid()} onClick={() => {
                                        let req = new JoinTeamRequest()
                                        req.setSession(settings.session!)
                                        req.setTeamuuid(listTeam.getUuid())
                                        request(api, req, p => p.joinTeam)
                                    }}>
                                        {listTeam.getName()}
                                        {!listTeam.getIslobby() && isOwnTeam
                                            ? <Button style={{
                                                backgroundColor: 'transparent',
                                                borderColor: 'transparent',
                                                marginLeft: '0.5em',
                                                padding: 0 // TODO: set `vertical-align: initial` on svg element
                                            }}
                                                onClick={() => {
                                                    let req = new SetTeamNameRequest()
                                                    req.setSession(settings.session!)
                                                    let name = window.prompt("New Teamname?")
                                                    if (name === null) return
                                                    req.setNewname(name)
                                                    req.setTeamuuid(listTeam.getName())
                                                    request(api, req, platform => platform.setTeamName)
                                                }}>
                                                <Octicon icon={Pencil} />
                                            </Button>
                                            : null}
                                        <span style={{ textAlign: 'right', float: 'right' }}>
                                            {members.length}
                                            {members.length === 1 ? ' member' : ' members'}
                                        </span>
                                    </ListGroupItem>
                                    {
                                        isOwnTeam
                                            ? members.map(member => {
                                                let online = member.getLastseentimestamp() > new Date().getTime() - 13000
                                                return <ListGroupItem style={{
                                                    textAlign: "right",
                                                    backgroundColor: 'rgba(0,0,0,0.2)',
                                                    color: online ? 'white' : 'red'
                                                }} key={member.getUuid()}>
                                                    {member.getName()}
                                                </ListGroupItem>
                                            })
                                            : null
                                    }
                                </React.Fragment>
                            })}
                        </ListGroup>
                    ) : null}
                </SmolCol>
            </Row>
        </Container >
    )
}