import React, { useState, useContext } from 'react'
import { Container, Row, Col, Jumbotron, Form, FormGroup, Label, Input, Button, ButtonGroup } from 'reactstrap'
import { APIState, request } from '../State/APIClient'
import { SpawnInstanceRequest, JoinInstanceRequest } from '../API/nodebuzz_pb'
import { SettingsState } from '../State/Settings'

export const Login: React.FC = () => {
    let api = useContext(APIState)
    let settings = useContext(SettingsState)
    let [instance, setInstance] = useState("quiznight-xx")
    let [authKey, setAuthKey] = useState("")


    return <Container>
        <Row style={{ paddingBottom: '1em' }}>
            <Col>
                <Jumbotron>
                    <Form style={{ textAlign: 'left' }}>
                        <FormGroup>
                            <Label>Instance:</Label>
                            <Input value={instance} onChange={e => setInstance(e.target.value)} />
                        </FormGroup>

                        <FormGroup>
                            <Label>AuthKey:</Label>
                            <Input value={authKey} onChange={e => setAuthKey(e.target.value)} />
                        </FormGroup>

                        <ButtonGroup style={{ float: 'right' }}>
                            <Button disabled={!api.platform} onClick={() => {
                                let req = new SpawnInstanceRequest()
                                req.setInstance(instance)
                                req.setAuthkey(authKey)
                                request(
                                    api,
                                    req,
                                    platform => platform.spawnInstance,
                                    resp => settings.setAdminSession(resp.getSession())
                                )
                            }}
                                style={{
                                    borderRight: 'black',
                                    borderRightStyle: 'solid',
                                }}
                            >Spawn Instance</Button>
                            <Button disabled={!api.platform} onClick={() => {
                                let req = new JoinInstanceRequest()
                                req.setInstance(instance)
                                req.setAuthkey(authKey)
                                request(
                                    api,
                                    req,
                                    platform => platform.joinInstance,
                                    resp => settings.setAdminSession(resp.getSession())
                                )
                            }}>Join Instance</Button>
                        </ButtonGroup>
                    </Form>
                </Jumbotron>
            </Col>
        </Row>
    </Container>


}